import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthContextProvider } from "./contexts/AuthContext";
import { ThemeProvider } from "@material-tailwind/react";
import { ApiContextProvider } from "./contexts/ApiCallsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="689980280198-5dbe8oaf5inb4a9gu9pt2hk8nrcskk1u.apps.googleusercontent.com">
    <React.StrictMode>
      <AuthContextProvider>
        <ApiContextProvider>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </ApiContextProvider>
      </AuthContextProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
