/* eslint-disable no-unused-vars */
import { createContext, useEffect, useState } from "react";
import { auth, db } from "../GoogleSignIn/config";
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc
} from "firebase/firestore";

export const AuthContext = createContext();

// eslint-disable-next-line react/prop-types
export const AuthContextProvider = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState(
    localStorage.getItem("userData") ? true : false
  );
  const [formsCount, setFormsCount] = useState(0);
  let params = new URL(document.location).searchParams;
  const [isUserDocStored, setIsUserDocStored] = useState(false);
  const [userDoc, setUserDoc] = useState(
    JSON.parse(sessionStorage.getItem("userDoc"))
  );

  //   useEffect(() => {
  //  if(){
  //     setIsAuthed(true);
  //  }
  //   }, []);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getCurrentUser();
      } else {
        console.log("No user logged in");
      }
    });

    return () => unsubscribe();
  }, []);

  // useEffect(()=>{getCurrentUser()})
  const getCurrentUser = async () => {
    try {
      const user = auth.currentUser;

      if (user) {
        const uid = user.uid;
        const userDocRef = doc(db, "userData", uid);

        const docSnapshot = await getDoc(userDocRef);

        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          sessionStorage.setItem("userDoc", JSON.stringify(userData));
          setUserDoc(userData);
        } else {
          const createUserDoc = await setDoc(doc(db, "userData", uid), {
            email: user.email
          });
          console.log("No document found for the current user.");
        }
      } else {
        sessionStorage.clear();
        console.log("No authenticated user found.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const signUp = async (email, password) => {
    try {
      const data = await createUserWithEmailAndPassword(auth, email, password);
      // localStorage.setItem('userData',JSON.stringify(auth.currentUser));
      // // setIsAuthed(JSON.parse(localStorage.getItem('userData')))
      return "success";
    } catch (err) {
      alert(err);
      console.error(err);
      return err;
    }
  };
  const signOut = async () => {
    try {
      await auth.signOut();
      sessionStorage.clear(); // Clear sessionStorage only after successful sign out
      localStorage.clear();
      window.location.reload();
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };
  const signIn = async (email, password) => {
    try {
      const data = await signInWithEmailAndPassword(auth, email, password);
      localStorage.setItem("userData", JSON.stringify(auth.currentUser));
      setIsAuthed(JSON.parse(localStorage.getItem("userData")));
      return "success";
    } catch (err) {
      // alert(err);
      if (err.includes("(auth/email-already-in-use)")) {
        return "email-in-use";
      }
      return "failure";
    }
  };
  const triggerResetEmail = async (email) => {
    sendPasswordResetEmail(auth, email);
  };
  const addUserData = async (profilePic, fName, dob, label, description) => {
    const user = auth.currentUser;

    try {
      const docRef = await setDoc(doc(db, "userData", user.uid), {
        profilePic: profilePic,
        fullName: fName,
        dob: dob,
        label: label,
        description: description,
        sociolink: params.get("claim")
      });

      console.log("User data added successfully:", docRef);

      localStorage.setItem("userData", JSON.stringify(auth.currentUser));
      setIsAuthed(JSON.parse(localStorage.getItem("userData")));
      getCurrentUser();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const checkUsernameAvailability = async (sociolinkIp) => {
    let isAvailable = true; // Assume it's available by default

    try {
      const querySnapshot = await getDocs(collection(db, "userData"));

      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        if (userData.sociolink) {
          const storedSociolink = userData.sociolink.toLowerCase();

          if (storedSociolink === sociolinkIp.toLowerCase()) {
            isAvailable = false; // Set to false if a match is found
            return; // Exit the loop early
          }
        }
      });

      if (isAvailable) {
        console.log("sociolink is available.");
      } else {
        console.log("sociolink is not available.");
      }

      return isAvailable;
    } catch (e) {
      console.error("Error checking sociolink availability: ", e);
      return false; // An error occurred, assume sociolink is not available
    }
  };

  const addSociolinkInUserData = async (sociolink) => {
    try {
      const user = auth.currentUser; // Assuming you have access to the authentication user object
      const uid = user.uid;

      // Reference to the document in "userData" collection based on the UID
      const userDocRef = doc(db, "userData", uid);

      // Check if the document exists
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        // The document exists
        const userData = userDocSnapshot.data();
        await updateDoc(userDocRef, {
          sociolink: sociolink
        });
        console.log("Sociolink added successfully.");
        return true;
      } else {
        console.log("User document not found.");
        return false;
      }
    } catch (error) {
      console.error("Error adding sociolink to userData:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthed,
        signIn,
        signUp,
        formsCount,
        setFormsCount,
        addUserData,
        checkUsernameAvailability,
        addSociolinkInUserData,
        signOut,
        getCurrentUser,
        isUserDocStored,
        userDoc,
        setUserDoc,
        triggerResetEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
