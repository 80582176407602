/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import Axios from "axios";
import { SHA1 } from "crypto-js";
import { createContext, useContext, useEffect, useState } from "react";
import { CloudinaryConfig } from "../utils/CloudinaryConfig";
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where
} from "firebase/firestore";
import { auth, db } from "../GoogleSignIn/config";

export const ApiContext = createContext();

// eslint-disable-next-line react/prop-types
const uploadImage = async (oldImage, image) => {
  const imgData = new FormData();
  imgData.append("file", image);
  imgData.append("upload_preset", "sociolink_preset");

  try {
    const response = await Axios.post(
      `https://api.cloudinary.com/v1_1/sociolink/image/upload`,
      imgData
    );

    console.log("Uploaded successfully", response);

    return response.data;
  } catch (err) {
    console.log(err);
    // Handle error appropriately, e.g., throw an error or return a default value
    throw err;
  }
};

//replace imagei in the cloudinary cdn
const replaceImage = async (oldImage, newImage) => {
  console.log(oldImage);
  let res;
  await deleteImage(oldImage)
    .then(async () => {
      await uploadImage(oldImage, newImage)
        .then((uploadResults) => {
          res = uploadResults;
          console.log("Image replaced successcully", uploadResults);
        })
        .catch((err) => {
          console.log("Error uploading image", err);
        });
    })
    .catch((err) => {
      console.log("Error deleting image", err);
    });
  return res;
};
const deleteImage = async (oldImage) => {
  try {
    const timestamp = Math.floor(new Date().getTime() / 1000);
    const signature = SHA1(
      `public_id=${oldImage.public_id}&timestamp=${timestamp}${CloudinaryConfig.CLOUNDINARY_SECRET}`
    ).toString();
    const uri = `https://api.cloudinary.com/v1_1/sociolink/image/destroy`;
    const formData = new FormData();
    formData.append("public_id", oldImage.public_id);
    formData.append("signature", signature);
    formData.append("api_key", CloudinaryConfig.CLOUDINARY_API_KEY);
    formData.append("timestamp", timestamp);
    const response = await Axios.post(uri, formData);
    console.log("Image deleted successfully", response);

    return response.data;
  } catch (error) {
    console.error("Error deleting image", error);
  }
};
//Edit user details in the firebase
const editUserProfile = async (values) => {
  const uid = JSON.parse(localStorage.getItem("userData")).uid;

  try {
    const userDocRef = doc(db, "userData", uid);
    await updateDoc(userDocRef, values);
    console.log("User data updated successfully");
    getCurrentUser(uid);
  } catch (error) {
    console.error("Error updating user data:", error);
  }
};
const getCurrentUser = async (uid) => {
  try {
    const userDocRef = doc(db, "userData", uid);

    const docSnapshot = await getDoc(userDocRef);

    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      sessionStorage.setItem("userDoc", JSON.stringify(userData));
      // setUserDoc(userData);
    } else {
      console.log("No document found for the current user.");
    }
  } catch (error) {
    console.error(error);
  }
};
const getUserDetailsWithSociolink = async (sociolinkText) => {
  try {
    let userDataToDisplay;
    const q = query(
      collection(db, "userData"),
      where("sociolink", "==", sociolinkText)
    );

    // Assuming you want to get the first document matching the query
    const docSnapshot = await getDocs(q);

    userDataToDisplay = docSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id
    }));

    return userDataToDisplay[0];
  } catch (error) {
    console.error(error);
  }
};
const checkIfSocioLinkExists = async (sociolink) => {
  const q = query(
    collection(db, "userData"),
    where("sociolink", "==", sociolink.toLowerCase())
  );
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    return false;
  } else {
    return true;
  }
};

export const ApiContextProvider = ({ children }) => {
  const [userRef, setUserRef] = useState(
    JSON.parse(sessionStorage.getItem("userDoc"))
  );
  const [linksArr, setLinksArr] = useState([]);
  const [likeDislikes, setLikeDislikes] = useState({ likes: 0, dislikes: 0 });

  const addLinkToSocioLink = async (values) => {
    try {
      const documentPath = Date.now().toString();
      const uploadedLink = await setDoc(
        doc(
          db,
          "Sociolinks",
          userRef.sociolink,
          "links",
          Date.now().toString()
        ),
        values
      );

      return uploadedLink;
    } catch (error) {
      console.error("Error adding link to sociolink", error);
      return error;
    }
  };
  const handleLinkDelete = async (id) => {
    const deletedLink = await deleteDoc(
      doc(db, "Sociolinks", userRef.sociolink, "links", id)
    );
  };
  const getSocioLinksFromDB = async (sociolink) => {
    console.log("called getSocioLinksFromDB", sociolink);
    try {
      const unsubscribe = onSnapshot(
        collection(db, "Sociolinks", sociolink, "links"),
        (querySnapshot) => {
          setLinksArr(
            querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          );
        }
      );
    } catch (error) {
      console.error("Error adding link to sociolink", error);
      return error;
    }
  };
  const updateSocialMediaLinksTInDb = async (values) => {
    try {
      const documentPath = Date.now().toString();
      const uploadedLink = await updateDoc(
        doc(db, "Sociolinks", userRef.sociolink, "links", values.id),
        values
      );

      return uploadedLink;
    } catch (error) {
      console.error("Error adding link to sociolink", error);
      return error;
    }
  };
  const addSocialMediaLinksToDb = async (values) => {
    const uid = JSON.parse(localStorage.getItem("userData")).uid;
    const docRef = doc(collection(db, "userData"));
    const colref = collection(docRef, uid);

    try {
      return await updateDoc(doc(db, "userData", uid), {
        socialMediaLinks: values.socialMediaLinks
      });
    } catch (error) {
      console.error(error);
    }
  };

  const showInterestInNewFeatures = async (feature, interested) => {
    const uid = JSON.parse(localStorage.getItem("userData")).uid;

    try {
      const userInterestDocRef = doc(db, "userInsterests", feature);
      const userInterestDoc = await getDoc(userInterestDocRef);

      let likes = [];
      let dislikes = [];

      if (userInterestDoc.exists()) {
        // If the document already exists, get the existing likes and dislikes arrays
        likes = userInterestDoc.data().likes || [];
        dislikes = userInterestDoc.data().dislikes || [];
      }

      // Update the likes or dislikes array based on the value of 'interested'
      if (interested) {
        likes.push(uid);
      } else {
        dislikes.push(uid);
      }

      // Update the document in the Firestore collection
      await setDoc(userInterestDocRef, { likes, dislikes });

      return "Interest submitted successfully!";
    } catch (error) {
      console.error("Error submitting interest:", error);
      return "Error submitting interest";
    }
  };
  const checkIfAlreadyResponded = async (feature) => {
    const uid = JSON.parse(localStorage.getItem("userData")).uid;

    try {
      const userInterestDocRef = doc(db, "userInsterests", feature);
      const userInterestDoc = await getDoc(userInterestDocRef);

      if (userInterestDoc.exists()) {
        const likes = userInterestDoc.data().likes || [];
        const dislikes = userInterestDoc.data().dislikes || [];

        //assigning lengths
        setLikeDislikes({ likes: likes.length, dislikes: dislikes.length });

        // Check if the user's UID is in the likes or dislikes arrays
        const alreadyLiked = likes.includes(uid);
        const alreadyDisliked = dislikes.includes(uid);

        if (alreadyLiked || alreadyDisliked) {
          // The user has already responded
          return {
            status: true,
            alreadyLiked: alreadyLiked,
            alreadyDisliked: alreadyDisliked,
            likes: likes.length,
            dislikes: dislikes.length
          };
        }
      }

      // The user has not responded yet
      return {
        status: false
      };
    } catch (error) {
      console.error("Error checking response status:", error);
      // Handle error as needed
      return false;
    }
  };

  return (
    <ApiContext.Provider
      value={{
        uploadImage,
        replaceImage,
        editUserProfile,
        addLinkToSocioLink,
        getSocioLinksFromDB,
        linksArr,
        getUserDetailsWithSociolink,
        checkIfSocioLinkExists,
        addSocialMediaLinksToDb,
        updateSocialMediaLinksTInDb,
        getCurrentUser,
        handleLinkDelete,
        showInterestInNewFeatures,
        checkIfAlreadyResponded,
        likeDislikes
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
