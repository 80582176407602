import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCNDHRoZ3SwsBHA2dg-hqGmM9cJ7lLd_dY",
  authDomain: "sociolink.firebaseapp.com",
  projectId: "sociolink",
  storageBucket: "sociolink.appspot.com",
  messagingSenderId: "689980280198",
  appId: "1:689980280198:web:75eeb4e4caa19d2de9b007",
  measurementId: "G-ZNNE8T8PMM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
export { auth, provider, db };
