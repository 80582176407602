import React, { Suspense, lazy, useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { AuthContext } from "./contexts/AuthContext";
import ReactGA from "react-ga4";
import { Footer } from "./reusables/Footer";

const LandingPage = lazy(() => import("./pages/LandingPage"));
const SignUp = lazy(() => import("./pages/SignUp"));
const Login = lazy(() => import("./pages/Login"));
const NotFound404 = lazy(() => import("./pages/NotFound404"));
const Dashboard = lazy(() => import("./pages/Dashboard"));

const UserProfilePage = lazy(() => import("./pages/UserProfilePage"));
const ClientPage = lazy(() => import("./pages/ClientPage"));

function App() {
  const { isAuthed, userDoc } = useContext(AuthContext);

  const GA_MEASUREMENT_ID = "G-ZNNE8T8PMM";

  ReactGA.initialize(GA_MEASUREMENT_ID);

  return (
    <div className="App-wrapper">
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="flex h-screen w-screen items-center justify-center">
              Loading...
            </div>
          }
        >
          <Routes>
            <Route
              exact
              path="/"
              element={
                userDoc ? (
                  userDoc && userDoc.sociolink != null ? (
                    <Navigate to="/dashboard" />
                  ) : (
                    <LandingPage />
                  )
                ) : (
                  <LandingPage />
                )
              }
            />
            <Route
              exact
              path="/login"
              element={isAuthed ? <Navigate to="/dashboard" /> : <Login />}
            />
            <Route
              exact
              path="/signup"
              element={isAuthed ? <Navigate to="/dashboard" /> : <SignUp />}
            />
            {/* <Route exact path="/discovery" element={<Discovery />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/learn" element={<Learn />} /> */}
            <Route
              exact
              path="/dashboard"
              element={
                isAuthed ? (
                  userDoc && userDoc.sociolink != null ? (
                    <Dashboard />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              exact
              path="/user-profile"
              element={
                isAuthed ? <UserProfilePage /> : <Navigate to="/login" />
              }
            />
            <Route exact path="/:id" element={<ClientPage />} />
            <Route path="*" element={<NotFound404 />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
